import $ from 'jquery';

let fieldsAreInitialized = false;

function changeFileUpload() {
  const multiFileUploads = document.querySelectorAll(
    '.gform_fileupload_multifile'
  );
  if (0 < multiFileUploads.length) {
    multiFileUploads.forEach(function (fileUpload) {
      fileUpload.querySelector('.gform_drop_instructions').textContent =
        wp.i18n._x(
          'Drag and drop file to upload',
          'drag and drop instruction',
          'vtx'
        );
    });
  }
}

function changeFormLabel(label, input) {
  if ('' !== input.value) {
    label.classList.add('active');
  }

  label.addEventListener('click', function () {
    input.focus();
  });

  input.addEventListener('focus', function () {
    label.classList.add('active');
  });

  input.addEventListener('focusout', function () {
    if (!input.value || '(___) ___-____' === input.value) {
      label.classList.remove('active');
    }
  });
}
//for login form
function addFormEvents() {
  const fields = document.querySelectorAll('.row--input');

  if (0 < fields.length) {
    fields.forEach(function (field) {
      if (field.classList.contains('initialized')) {
        return;
      }

      field.classList.add('initialized');

      const label = field.querySelector('.woocommerce-label');
      const input = field.querySelector('.woocommerce-Input');

      if (label && input) {
        changeFormLabel(label, input);
      }
    });
  }
}

$(document).on('gform_post_render', function () {
  fieldsAreInitialized = true;
  changeFileUpload();
  addFormEvents();
});

// ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    changeFileUpload();
    addFormEvents();
  }
});
